




























































import Vue, { PropType } from "vue";
import { mapState } from "vuex";

interface Message {
  type: string;
  author: string;
  data: { text: string };
}

export default Vue.extend({
  name: "VChatBox",
  props: {
    messageList: Array as PropType<Message[]>,
    loading: Boolean
  },
  data() {
    return {
      dataSource: [] as string[],
      text: "",
      scrollInterval: 0
    };
  },
  computed: {
    ...mapState(["user"]),
    ...mapState("app", {
      isMobile: "isMobile"
    }),
    ...mapState("assets", {
      images: "images"
    }),
    textarea(): HTMLTextAreaElement {
      return this.$refs.textarea as HTMLTextAreaElement;
    },
    messageListContainer(): HTMLDivElement {
      return this.$refs.messageListContainer as HTMLDivElement;
    }
  },
  methods: {
    handleMenuClick({ key }: { key: string }) {
      this.text = "/" + key + " ";
      this.textarea.focus();
    },
    onSend() {
      if (!this.text || this.loading) return;
      this.textarea.focus();
      this.$emit("send", {
        author: "me",
        type: "text",
        data: { text: this.text }
      });
      this.text = "";
    },
    scrollToBottom() {
      const scrollHeight = this.messageListContainer.scrollHeight;
      const height = this.messageListContainer.clientHeight;
      const maxScrollTop = scrollHeight - height;
      let currentScrollTop = this.messageListContainer.scrollTop;
      const step = Math.ceil((maxScrollTop - currentScrollTop) / 20);
      this.scrollInterval = setInterval(() => {
        if (currentScrollTop < maxScrollTop) {
          currentScrollTop += step;
          if (currentScrollTop > maxScrollTop) {
            currentScrollTop = maxScrollTop;
          }
          this.messageListContainer.scrollTop = currentScrollTop;
        } else {
          clearInterval(this.scrollInterval);
        }
      }, 20);
    }
  },
  watch: {
    messageList() {
      this.scrollToBottom();
    }
  },
  mounted() {
    this.textarea.focus();
  }
});
