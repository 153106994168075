












import Vue from "vue";
import { mapState } from "vuex";
import { dispatch } from "@/store";

export default Vue.extend({
  name: "VTaskSubscribe",
  props: {
    taskId: Number
  },
  data() {
    return {
      email: "",
      loading: false
    };
  },
  computed: {
    ...mapState(["assets"]),
    ...mapState("app", {
      isCN: "isCN"
    })
  },
  methods: {
    onSubmit() {
      const emailRe = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
      if (!emailRe.test(this.email)) {
        this.$message.info("" + this.$t("inputError", [this.$t("email")]));
        return;
      }
      this.loading = true;
      dispatch
        .taskSubscribe({
          email: this.email,
          task_id: this.taskId
        })
        .then(() => {
          this.$message.info(this.$t("AM65LessCustom.submitSuccessfully") + "");
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    }
  }
});
