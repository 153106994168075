






























































































































import Vue, { PropType } from "vue";
import { mapState } from "vuex";
import moment from "moment";
import { Swiper } from "swiper";
import { dispatch, Task, TasksVote, Media } from "@/store";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";

export default Vue.extend({
  name: "VVote",
  props: {
    isAdd: Boolean,
    isEdit: Boolean,
    value: Object as PropType<TasksVote>,
    task: Object as PropType<Task>
  },
  data() {
    return {
      moment,
      uploadIndex: 0,
      checkedIds: [] as number[],
      visible: false,
      voteOptions: {
        speed: 600,
        shortSwipes: false,
        longSwipesMs: 50,
        longSwipesRatio: 0.05,
        initialSlide: 1,
        navigation: {
          prevEl: ".vote-prev",
          nextEl: ".vote-next"
        }
      }
    };
  },
  computed: {
    ...mapState({ user: "user" }),
    ...mapState("assets", {
      images: "images"
    }),
    canVote(): boolean {
      return (
        !this.isAdd &&
        !this.isEdit &&
        !this.task.vote_content.show_res &&
        this.task.status !== "closed" &&
        this.task.user_info?.id !== this.user.id &&
        moment().isAfter(this.task.start_at) &&
        moment().isBefore(this.task.end_at)
      );
    },
    swiper(): Swiper {
      return (this.$refs.voteSwiper as any).$swiper;
    }
  },
  methods: {
    canEdit(id?: number): boolean {
      return (this.isAdd || this.isEdit) && !id;
    },
    getSignImages() {
      const fileList: string[] = [];
      this.value.vote_list.map(vote => {
        if (vote.pic) fileList.push(vote.pic);
      });
      if (fileList.length) {
        dispatch.filesGet(fileList, Number(this.task.id)).then(res => {
          this.value.vote_list.map(vote => {
            if (vote.pic) {
              res.find((item: any) => {
                if (vote.pic.includes(item.request_url)) {
                  vote.pic = item.sign_url;
                  return true;
                }
              });
            }
          });
          this.$emit("change", { ...this.value });
        });
      }
    },
    onClick(id: number) {
      if (this.canVote) {
        const maxNum = Number(this.task.vote_conf.max_num);
        if (this.checkedIds.includes(id)) {
          if (maxNum > 1) {
            this.checkedIds.splice(
              this.checkedIds.findIndex(i => i === id),
              1
            );
          }
        } else {
          if (this.checkedIds.length < maxNum) {
            this.checkedIds.push(id);
          } else if (maxNum === 1) {
            this.checkedIds = [id];
          } else {
            this.$message.info("" + this.$t("task.voteMultiple", [maxNum]));
          }
        }
      } else if (!this.isAdd && !this.isEdit) {
        if (this.task.user_info?.id === this.user.id) {
          this.$message.info("" + this.$t("task.voteSelf"));
          return;
        }
        if (moment().isBefore(this.task.start_at)) {
          this.$message.info("" + this.$t("task.voteNotStart"));
          return;
        }
        if (
          this.task.status === "closed" ||
          moment().isAfter(this.task.end_at)
        ) {
          this.$message.info("" + this.$t("task.voteEnded"));
          return;
        }
      }
    },
    onImageClick(index: number) {
      const id = this.value.vote_list[index].id;
      if (this.canEdit(id)) {
        const upload = (this.$refs.upload as any).$el.querySelector("input");
        if (upload) {
          upload.click();
          this.uploadIndex = index;
        }
      } else {
        const initialSlide = this.value.vote_list
          .filter(vote => vote.pic)
          .findIndex(vote => vote === this.value.vote_list[index]);
        if (initialSlide !== -1) {
          this.visible = true;
          this.voteOptions.initialSlide = initialSlide;
        }
      }
    },
    onUploaded(media: Media) {
      this.value.vote_list[this.uploadIndex].pic = media.url;
      this.$emit("change", { ...this.value });
    },
    onContentChange(index: number, content: string) {
      this.value.vote_list[index].content = content;
      this.$emit("change", { ...this.value });
    },
    onAdd() {
      this.value.vote_list.push({ content: "", pic: "" });
      this.$emit("change", { ...this.value });
    },
    onRemove(index: number) {
      this.value.vote_list.splice(index, 1);
      this.$emit("change", { ...this.value });
      const maxNum = Number(this.task.vote_conf.max_num);
      if (maxNum > this.value.vote_list.length) {
        this.onMaxNumChange(this.value.vote_list.length + "");
      }
    },
    onMaxNumChange(maxNum: string) {
      this.$emit("maxNumChange", maxNum);
    }
  },
  mounted() {
    this.getSignImages();
    setTimeout(() => {
      this.swiper.init();
    }, 2000);
  }
});
