
































































































































































import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import moment from "moment";
import { dispatch, ProductName, Product } from "@/store";

export default Vue.extend({
  data() {
    return {
      moment,
      time: moment() as moment.Moment
    };
  },
  computed: {
    ...mapState("app", {
      isCN: "isCN",
      language: "language",
      isMobile: "isMobile",
      products: "products"
    }),
    ...mapState("assets", {
      images: "images",
      oss: "oss",
      url: "url"
    }),
    ...mapGetters({
      homeProducts: "homeProducts",
      getStatus: "getStatus"
    }),
    titleList(): string[] {
      return this.isCN
        ? [
            ...this.homeProducts.map(
              (p: Product) => p[this.isCN ? "cn_top_name" : "overseas_top_name"]
            ),
            "AM MASTER",
            "ABOUT US",
            "FOOTER"
          ]
        : [
            "Eclipse",
            "AM NEON 80",
            ...this.homeProducts.map(
              (p: Product) => p[this.isCN ? "cn_top_name" : "overseas_top_name"]
            ),
            "ACCESSORIES",
            "ABOUT US",
            "FOOTER"
          ];
    },
    end(): string {
      return this.isCN
        ? "2022-08-30T14:00:00+08:00"
        : "2022-09-01T23:00:00+08:00";
    }
  },
  methods: {
    onTimeChange(time: string) {
      if (!time) {
        window.location.reload();
      }
      this.time = moment();
    },
    buttonText(name: ProductName) {
      if (this.getStatus(name) === "restock") {
        return this.$t("preorder") + "";
      } else if (this.getStatus(name) === "GB") {
        return this.$t("preorder") + "";
      } else if (this.getStatus(name) === "inStock") {
        return this.$t("buy") + "";
      }
      return this.$t("learnMore") + "";
    },
    onProductClick(name: ProductName) {
      if (this.getStatus(name) === "restock") {
        this.orderNow(name);
      } else if (this.getStatus(name) === "GB") {
        this.orderNow(name);
      } else if (this.getStatus(name) === "inStock") {
        this.orderNow(name);
      } else {
        this.learnMore(name);
      }
    },
    learnMore(name: ProductName) {
      dispatch.learnMore(name);
    },
    orderNow(name?: ProductName) {
      dispatch.orderNow(name);
    },
    followUs(name?: ProductName) {
      dispatch.followUs(name);
    },
    goTask(id: number) {
      if (this.isMobile) {
        this.$router.push(this.$paths.dao + id + "/");
      } else {
        window.open(this.$paths.dao + id + "/", "_blank");
      }
    },
    open(url: string) {
      window.open(url, "_blank");
    },
    onR4Click() {
      this.isCN
        ? this.$router.push(this.$paths.cyberboardR4)
        : window.open(this.url.discord, "_blank");
    }
  }
});
