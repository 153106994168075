

































































import Vue from "vue";
import ClipboardJS from "clipboard";
import { mapState } from "vuex";

export default Vue.extend({
  name: "VShare",
  props: {
    title: String,
    url: String
  },
  data() {
    return {
      visible: false,
      clipboard: null as any
    };
  },
  computed: {
    ...mapState({ assets: "assets" }),
    ...mapState("app", {
      isCN: "isCN"
    }),
    shareUrl(): string {
      return this.url || window.location.origin + this.$route.fullPath;
    },
    copyText(): string {
      return `${this.title} - Angry Miao\n${this.shareUrl}`;
    }
  },
  methods: {
    onShare(channel: string) {
      const url = encodeURIComponent(this.shareUrl);
      const title = encodeURIComponent(this.title + " - Angry Miao");
      const desc = encodeURIComponent("Future Art Community");
      const image = encodeURIComponent(this.assets.url.logo);
      switch (channel) {
        case "weibo":
          window.open(
            `https://service.weibo.com/share/share.php?url=${url}&title=${title}&pic=`,
            "_blank"
          );
          break;
        case "qzone":
          window.open(
            `https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=${url}&site=${title}&title=${title}&desc=${desc}&summary=${desc}&pics=${image}`,
            "_blank"
          );
          break;
        case "qq":
          window.open(
            `https://connect.qq.com/widget/shareqq/index.html?url=${url}&site=${title}&title=${title}&desc=${desc}&summary=${desc}&pics=${image}`,
            "_blank"
          );
          break;
        case "weixin":
          (this.$refs.clipboard as HTMLElement).click();
          break;
        case "bilibili":
          (this.$refs.clipboard as HTMLElement).click();
          break;
        case "twitter":
          window.open(
            `https://twitter.com/intent/tweet?text=${title}&url=${url}`,
            "_blank"
          );
          break;
        case "facebook":
          window.open(
            `https://www.facebook.com/sharer/sharer.php?href=${url}`,
            "_blank"
          );
          break;
        case "pinterest":
          window.open(
            `https://www.pinterest.com/pin/create/button/?url=${url}`,
            "_blank"
          );
          break;
      }
    }
  },
  watch: {
    visible(visible) {
      if (visible) {
        setTimeout(() => {
          this.clipboard = new ClipboardJS("#clipboard");
          this.clipboard.on("success", () => {
            this.$message.info(this.$t("share.copied") + "");
          });
        }, 200);
      } else if (this.clipboard) {
        this.clipboard.destroy();
      }
    }
  }
});
